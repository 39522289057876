<template>
  <div class="show-job">
    <!-- title BEGIN -->
    <div class="show-job__heading">
      <h2 class="show-job__title">
        Job Number #{{ jobInfo.serviceJobID }}
        <status-tag
          v-if="showStatusTag && jobInfo.jobStatus"
          :state="jobInfo.jobStatus"
          class="show-job__status-tag"
        />
      </h2>
    </div>
    <!-- title END -->
    <!-- info BEGIN -->
    <div class="show-job__columns">
      <div class="show-job__column show-job__column--first">
        <div class="show-job__description">
          <div class="show-job__status">{{ statusHeading }}</div>
          <slot v-if="!loader"></slot>
          <template v-if="loader">
            <content-placeholders>
              <content-placeholders-text :lines="4" />
            </content-placeholders>
          </template>
        </div>
      </div>
      <div class="show-job__column show-job__column--last">
        <div class="show-job__row">
          <div class="show-job__label">Service Device</div>
          <div class="show-job__value show-job__device-name">
            {{ deviceText }}
          </div>

          <div class="show-job__label">Service Type</div>
          <div class="show-job__value show-job__service-name">{{ serviceName }}</div>
        </div>
        <div class="show-job__row">
          <div class="show-job__date">
            Service requested <br />
            <span>{{ serviceDate }}</span>
          </div>
          <div>
            <button
              v-if="showCancel"
              :disabled="cancelDisabled || pendingRequest"
              class="show-job__cancel-button hs-link"
              @click="showCancellationConfirmation"
              @keydown.enter="showCancellationConfirmation"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- info END -->
    <!-- history BEGIN -->
    <template v-if="showProgressHistory">
      <div class="show-job__heading show-job__heading--claims">
        <h2 class="show-job__title">Progress History</h2>
      </div>
      <JobClaims :job="jobInfo" :serviceDate="serviceDate" />
    </template>
    <!-- history END -->

    <CancellationPopup
      v-if="isCancellationConfirmationVisible"
      v-bind="cancellationPopupData"
      :disabled="cancelDisabled || pendingRequest"
      :decline-disabled="cancelDisabled || pendingRequest"
      @hide="hideCancellationConfirmation"
      @confirm="cancelClaim"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import StatusTag from '@/components/shared/ClaimStatusTag';
import JobClaims from '@/components/job/Claims';
import CancellationPopup from '@/components/shared/CancellationPopup';
import claimTypesNames from '@/constants/claim_types_names';

export default {
  props: {
    jobInfo: {
      required: true,
    },
    loader: {
      required: false,
    },
    statusHeading: {
      type: String,
      required: true,
    },
    showStatusTag: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    showProgressHistory: {
      type: Boolean,
      default: true,
    },
    cancellationPopupData: {
      type: Object,
    },
    manualCancellation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StatusTag,
    JobClaims,
    CancellationPopup,
  },
  data: () => ({
    isCancellationConfirmationVisible: false,
  }),
  methods: {
    ...mapActions('requestService', ['CANCEL_SERVIFY_CLAIM']),
    ...mapActions('deviceInformation', ['GET_DEVICE_INFO']),
    ...mapActions('user', ['DECRYPT_PARAMS']),
    showCancellationConfirmation() {
      this.isCancellationConfirmationVisible = true;
    },
    hideCancellationConfirmation() {
      this.isCancellationConfirmationVisible = false;
    },
    async cancelClaim() {
      if (!this.manualCancellation) {
        const {customerRef, contractRef} = this.$route.query;
        const contract = this.contractDetails?.ContractBasicDetail || {};
        const PartnerCode = contract?.PartnerCode;

        await this.CANCEL_SERVIFY_CLAIM({
          customerRef,
          contractRef,

          claim: this.jobInfo,

          PartnerCode,
        });

        this.hideCancellationConfirmation();
        this.$emit('rerequest');
      } else {
        this.$emit('cancel');
      }
    },
  },
  computed: {
    ...mapState('deviceInformation', ['equipmentName', 'modelName']),
    ...mapState('requestService', [
      'cancelServifyClaimInProgress',
      'acceptBuyoutInProgress',
      'rejectBuyoutInProgress',
      'acceptReplacementInProgress',
    ]),
    ...mapState('user', ['contractDetails']),
    pendingRequest() {
      const {
        cancelServifyClaimInProgress,
        acceptBuyoutInProgress,
        rejectBuyoutInProgress,
        acceptReplacementInProgress,
      } = this;
      return (
        cancelServifyClaimInProgress ||
        acceptBuyoutInProgress ||
        rejectBuyoutInProgress ||
        acceptReplacementInProgress
      );
    },
    serviceName() {
      // TODO: Uppercase is used because of inconsistent job type from ESB e.g. 'Replacement'
      // TODO: See DPD-4171. Remove when fixed on ESB side.
      return claimTypesNames[this.jobInfo?.serviceJobType.toUpperCase()];
    },
    deviceText() {
      return this.equipmentName || this.modelName || this.deviceId;
    },
    serviceDate() {
      let d = this.jobInfo?.claimDetails?.createDate;
      if (!d) {
        return '-';
      }
      d = new Date(d);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    },
    deviceId() {
      return this.jobInfo.claimDetails.inventoryEquipmentID || '';
    },
  },
  watch: {
    deviceId: {
      async handler(id) {
        if (id) {
          const {query} = this.$route;
          const {customerRef} = await this.DECRYPT_PARAMS({
            customerRef: query.customerRef,
          });

          await this.GET_DEVICE_INFO({id, customerRef});
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.show-job {
  font-size: 18px;
  &__title {
    display: flex;
    align-items: center;
    color: $black;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
  &__description {
    padding: 22px 16px;
    border: 1px dashed #d1d1d1;
    border-left: 4px solid #e62419;

    @include mf($gridMDBreakpoint) {
      padding: 18px 26px;
    }
  }
  &__status {
    margin: 0 0 9px;
    color: $black;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    &-tag {
      margin-left: 17px;
    }
  }
  &__heading {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    @include mf($gridMDBreakpoint) {
      align-items: center;
      margin-top: 20px;
    }

    &--claims {
      margin-top: 68px;
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;

    @include mf($gridMDBreakpoint) {
      flex-wrap: nowrap;
      margin-top: 40px;
    }
  }
  &__column {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 20px;

    @include mf($gridMDBreakpoint) {
      flex: inherit;
      margin-top: 0;
      padding-left: 60px;
      &--first {
        width: 722px;
        padding-left: 0;
      }
    }
    &--last {
      display: grid;
      flex-grow: 1;
      grid-template-columns: 1fr 1fr;
      width: 470px;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: unset;

    &:nth-child(even) {
      align-items: flex-end;
    }

    @include mf($gridMDBreakpoint) {
      margin: 0;
    }

    @include mf($gridXSBreakpoint) {
      margin: 2% 10%;
    }
  }
  &__label {
    margin-top: 31px;
    color: $black;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    &:first-child {
      margin-top: 0;
    }
  }
  &__value {
    margin-top: 6px;
    color: $darkGray;
    font-size: 18px;
    line-height: 24px;
  }

  &__date {
    padding-bottom: 25px;
    color: $mediumGray;
    font-size: 14px;
    line-height: 100%;
    text-align: right;

    @include mf($gridMDBreakpoint) {
      font-size: 16px;
      text-align: right;
    }
    span {
      display: block;
      margin-top: 5px;
    }
  }
  &__cancel-button {
    margin-top: 22px;
    font-size: 18px;
    &:disabled {
      opacity: 0.5;
    }
  }
}
</style>
