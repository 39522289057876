<template>
  <div>
    <wrapper-with-back :link="backLink" class="show-reimbursement">
      <ShowJob
        :loader="showBuyoutAmountLoader"
        :job-info="jobInfo"
        :status-heading="statusHeading"
        :show-cancel="hasStatus('selected')"
        @cancel="cancelReimbursement"
        :cancel-disabled="updateClaimMasterInProgress"
        :cancellation-popup-data="cancellationPopupData.job"
        manual-cancellation
      >
        <template v-if="hasStatus('selected')">
          <UploadReceipt />
        </template>
        <div v-else v-html="statusHtml" class="show-reimbursement__text"></div>
      </ShowJob>
    </wrapper-with-back>
  </div>
</template>
<script>
import ShowJob from './ShowJob';
import {mapActions, mapState} from 'vuex';
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';
import {statuses, statusCodesResolver} from '@/constants/reimbursement/statuses';
import UploadReceipt from '@/components/claim/components/reimbursement/UploadReceipt';
import cancellationPopupData from '@/constants/reimbursement/cancellation_popup_data';

export default {
  components: {
    ShowJob,
    UploadReceipt,
  },

  mixins: [jobStatusMappingMixin],

  methods: {
    ...mapActions('requestService', ['UPDATE_MASTER_CLAIM']),

    async cancelReimbursement() {
      const {customerRef, contractRef} = this.$route.query;

      const contract = this.contractDetails?.ContractBasicDetail || {};
      const PartnerCode = contract?.PartnerCode;

      await this.UPDATE_MASTER_CLAIM({
        customerRef,
        contractRef,
        claim: this.jobInfo,

        params: {
          serviceJobStatus: 'CANCELLED',
          serviceJobStatusName: 'Cancelled',

          PartnerCode,
        },
      });

      this.$emit('rerequest');
    },
  },

  computed: {
    ...mapState('requestService', ['updateClaimMasterInProgress']),
    ...mapState('user', ['contractDetails']),
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },
    statuses: () => statuses,
    statusCodesResolver: () => statusCodesResolver,

    cancellationPopupData: () => cancellationPopupData,
    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
  },
};
</script>
<style lang="scss" scoped>
.show-reimbursement {
  padding: 20px 0;
  color: $darkGray;
  font-size: 18px;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }
}
</style>
